@media screen and (max-width: 1350px) {
    .navResponsive {
        display: none !important;
    }
}

.navResponsiveIcon {
    cursor: pointer;
    padding: 6px;
    border-radius: 4px;
}

.navResponsiveIcon:hover {
    background-color: #efefef;
}

@media screen and (min-width: 1350px) {
    .navResponsiveIcon,
    .navExpandedMenu,
    .navExpandedMenuItem {
        display: none !important;
    }

    .pl100 {
        padding-left: 10%;
    }
}

@keyframes expandDown {
    from {
        height: 0px;
    }

    to {
        height: 520px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
        ;
    }
}


.navExpandedMenu {
    animation-name: expandDown;
    animation-duration: 0.5s;
}

.navExpandedMenuItem {
    animation-name: fadeIn;
    animation-duration: 2s;
}