.animateIcon {
    display: inline-block;
    animation: upDown 1s ease-in-out infinite;
}

@keyframes upDown {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
        /* Adjust this value for the up distance */
    }

    100% {
        transform: translateY(0);
    }
}